import styled from "styled-components";

export const TeamMembersList = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-flow: column;
  }

  > figure {
    display: block;
    width: 30%;
    margin-bottom: 2em;

    > figcaption {
      margin-top: 1em;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;
