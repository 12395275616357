import React, { FC } from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import { Query } from "../generated/graphql";
import TeamMember from "../components/teamMembers/teamMember";
import { FluidObject } from "gatsby-image";
import { H2, Paragraph } from "../components/ui/typography";
import { Header } from "../components/ui/semanticElements/header";
import { TeamMembersList } from "../components/teamMembers";

const TeamPage: FC = () => {
  const data = useStaticQuery<Query>(graphql`
    query {
      allContentfulTeam {
        nodes {
          seoPageTitle
          seoPageDescription
          sectionTitle
          sectionIntro
          teamMember {
            id
            name
            position
            biography
            foto {
              title
              description
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            github
            linkedIn
          }
        }
      }
    }
  `);
  const {
    seoPageTitle,
    seoPageDescription,
    sectionTitle,
    sectionIntro,
    teamMember
  } = data.allContentfulTeam.nodes[0];

  return (
    <Layout pageTitle={seoPageTitle as string} pageDescription={seoPageDescription as string}>
      <Header>
        <H2 as="h1">{sectionTitle}</H2>
        <Paragraph as="h2">{sectionIntro}</Paragraph>
      </Header>
      <TeamMembersList>
        {teamMember?.map(m => {
          if (m) {
            return (
              <TeamMember
                key={m.name as string}
                name={m.name as string}
                position={m.position as string}
                biography={m.biography as string}
                foto={{
                  title: m.foto?.title as string,
                  description: m.foto?.description as string,
                  fluid: { ...m.foto?.fluid, aspectRatio: 1 } as FluidObject
                }}
                github={m.github as string}
                linkedIn={m.linkedIn as string}
              />
            );
          }
        })}
      </TeamMembersList>
    </Layout>
  );
};

export default TeamPage;
