import React, { FunctionComponent } from "react";

import Img, { FluidObject } from "gatsby-image";
import { ContentfulFluid } from "../../../generated/graphql";
import { ExternalLink, H6, Paragraph } from "../../ui/typography";

type TeamMemberProps = {
  name: string;
  position: string;
  biography: string;
  foto: Foto;
  github?: string;
  linkedIn?: string;
};

export type Foto = {
  title: string;
  description: string;
  fluid?: ContentfulFluid | null;
};

// TODO transform it in a flip card https://codepen.io/kacpertn4t/pen/RYzZwG

const TeamMember: FunctionComponent<TeamMemberProps> = ({
  name,
  position,
  biography,
  foto,
  github,
  linkedIn
}) => (
  <figure>
    <Img fluid={foto.fluid as FluidObject} alt={foto.description} />
    <figcaption>
      <H6>{name}</H6>
      <Paragraph>{position}</Paragraph>
      <Paragraph>{biography}</Paragraph>
      <div>
        {linkedIn && (
          <ExternalLink href={linkedIn} target="_blank" rel="noopener noreferrer">
            LinkedIn
          </ExternalLink>
        )}
        {github && (
          <ExternalLink href={github} target="_blank" rel="noopener noreferrer">
            Github
          </ExternalLink>
        )}
      </div>
    </figcaption>
  </figure>
);

export default TeamMember;
